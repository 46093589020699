export default [{
    "name": "Tech Coordinator",
    "description": `

   Pushnews is a push notification tool made for web marketers,
   being easy to use and install. 
   We are one of the greatest push notification platforms in Brazil 
   reaching hundreds of millions of people daily and billions monthly.
   
   I was hired to architect, manage, and build a new platform to deliver 
   push notifications.
   `,
    "slug": "pushnews/tech-coordinator.txt",
    "company": "Pushnews.eu",
    "technologies": ["kubernetes", "serverless", "node", "go", "google_cloud", "scrum", "redis", "pubsub", "docker", "ci", "cd"],
    "roles": ["architect", "manager", "developer"],
    "years": ["2019", "2020", "current"]
},
{
  "name": "CTO - Founder",
  "description": `
  
   Tuuris was a marketplace for traditional travel agency to reach internet consumers.
   I managed a small group of developers, oriented them to learn the stack, and build the project.
  `,
  "slug": "tuuris/cto.txt",
  "company": "Sample Company",
  "technologies": ["ruby", "rails", "docker", "vue", "google_cloud", "scrum", "rancher", "ci", "cd"],
  "roles": ["founder", "architect", "manager", "developer"],
  "years": ["2017", "2018", "2019", "2020"]
},
{
  "name": "Senior Full Stack Engineer",
  "description": `
  
  Worked mainly with a project called Admanager a mobile ad platform, that reaches millions of people daily.
  `,
  "slug": "mindbase/full_stack.txt",
  "company": "Ntxdev (Mindbase)",
  "technologies": ["react", "ruby", "rails", "postgresql", "tdd", "scrum", "aws"],
  "roles": ["architect", "developer"],
  "years": ["2018", "2019"]
},
{
  "name": "Head of Technology - Founder",
  "description": `
  
  I was in charge of the technology team and responsible for implementing and training all developers.
  `,
  "slug": "encontrebarato/head_of_tech.txt",
  "company": "Encontre Barato",
  "technologies": ["mysql", "ruby", "rails", "elasticsearch", "redis", "aws", "docker"],
  "roles": ["architect", "developer", "founder"],
  "years": ["2012", "2013", "2014", "2015", "2016", "2017"]
},
{
  "name": "Senior PHP Developer",
  "description": `
  
  My mission was to manage a small group of developers and to build a Lead distribution.
  `,
  "slug": "adclick/senior_php_developer.txt",
  "company": "Adclick",
  "technologies": ["mysql", "php", "symfony", "js", "css", "aws", "microservices"],
  "roles": ["manager", "developer"],
  "years": ["2013", "2014"]
},
{
  "name": "Product Manager",
  "description": `
  
  Developing a product to automate campaigns of SEM with Google AdWords API.
  Formatting SEO and GTO consulting product.
  `,
  "slug": "leadmedia/senior_php_developer.txt",
  "company": "Media Factory / Lead Media",
  "technologies": ["mysql", "php", "ruby","rails", "js", "css", "aws", "seo"],
  "roles": ["manager", "developer"],
  "years": ["2011", "2012", "2013"]
},
{
  "name": "Lead Ruby on Rails Developer",
  "description": `
  
  I was in charge of build a project management platform for SEO professionals.
  Managed a small group of developers.
  `,
  "slug": "mestreseo/lead_ruby_on_rails_developer.txt",
  "company": "Media Factory / Lead Media",
  "technologies": ["ruby","rails", "js", "css", "seo"],
  "roles": ["manager", "developer", "architect"],
  "years": ["2009", "2010", "2011"]
},
];