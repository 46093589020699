import React from 'react';
import {createPaperRecord} from '../outputs/paper'

export default {
    "function": (state, opts) => {
        return {
            output:  createPaperRecord( "Not so fast, Hackerman!",
              <img alt="Hackerman" style={{maxHeight: 350, maxWidth:350}} src="/hackerman.png" />
            )
        };
    },
    "optDef": {}
}