import firebase from 'firebase';
// Required for side-effects
firebase.initializeApp({
    apiKey: "AIzaSyCXkYYwJTbNFvTmE5Rzkx-hDu0keOBykvk",
    authDomain: "tamakiapp.firebaseapp.com",
    databaseURL: "https://tamakiapp.firebaseio.com",
    projectId: "tamakiapp",
});

export default (message) => {
    firebase.functions().httpsCallable('discord')({text: message})
        .then(() => console.log("Message success"))
        .catch( e => console.log(e))
}
