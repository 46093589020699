export default {
  "/academic/computer_science_bachelor/unifei": { content: `
  UNIFEI - Universidade Federal de Itajubá
  Years: 2005 - 2010

  Bachelor, Computer Science
  
  ` },
  "/academic/web_engineering_specialization/unifei": { content: `
  UNIFEI - Universidade Federal de Itajubá
  Years: 2010 - 2011

  Specialist, WEB Engineering
  
  ` },
  "/academic/platform_strategies/mit": { content: `
  Massachusetts Institute of Technology
  Years: 2018

  Platform Strategies, Digital Transformation

  ` },
}