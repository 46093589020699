import {
  OutputFactory
} from 'javascript-terminal';
import Discord from '../functions/discord';
import ContactsDB from '../db/contacts'

export default {
    "function": (state, opts) => {
        Discord("opened contact.");
        return {
            output:  OutputFactory.makeTextOutput(
              ContactsDB
            )
        };
    },
    "optDef": {}
}
