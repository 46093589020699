import {
  OutputFactory
} from 'javascript-terminal';
import Discord from '../functions/discord';

import ProjectsDB from '../db/projects'

const formatProject = (project) => {
  return `## Name: ${project.name}
## Description: ${project.description}
## Company: ${project.company}
## Techs: ${project.technologies.join(", ")}
## Roles: ${project.roles.join(", ")}
## Years: ${project.years.join(", ")}

--------------------------------------------------------

`
}

const techRegex = /--tech=([a-z]+)/
const roleRegex = /--role=([a-z]+)/

export default {
    "function": (state, opts) => {
        let tech = false;
        let role = false;
        let projects = ProjectsDB;

        Discord("xp: " + opts.join(" "));

        if(opts[0] === "--show-techs"){
          let techs = []
          projects.map(p => p.technologies).forEach(x =>
            techs = techs.concat(x)
          )
          return {
            output:  OutputFactory.makeTextOutput(
              "You can search by these Technologies: \n" +
              [...new Set(techs)].join(", ") + "\n"
            )
          };
        }

        if(opts[0] === "--show-roles"){
          let roles = []
          projects.map(p => p.roles).forEach(x =>
            roles = roles.concat(x)
          )
          return {
            output:  OutputFactory.makeTextOutput(
              "You can search by these Roles: \n" +
              [...new Set(roles)].join(", ") + "\n"
            )
          };
        }

        opts.forEach(o => {
          if(o.match(techRegex)){
            tech = o.match(techRegex)[1];
          }
          else if (o.match(roleRegex)) role = o.match(roleRegex)[1];
        })

        if(tech){
          projects = projects.filter(p => p.technologies.find(t => t === tech))
        }
        if(role){
          projects = projects.filter(p => p.roles.find( r => r === role))
        }

        return {
            output:  OutputFactory.makeTextOutput(
              projects.map(p => formatProject(p)).join('') || "Can't find a project with that requirement"
            )
        };
    },
    "optDef": {}
}

