import {
  OutputFactory
} from 'javascript-terminal';
import Discord from '../functions/discord'

const meetingText = `
  Please Schedule our meeting with this link:
    https://calendly.com/eduardotamaki/

`

export default {
    "function": (state, opts) => {
        Discord("someone open the meeting")
        return {
            output:  OutputFactory.makeTextOutput(
              meetingText
            )
        };
    },
    "optDef": {}
}
