const entryText = `## Hi I'm Eduardo Tamaki
## This is a fun way to present myself!
##
## If you are not familiar with terminal,
## please go to my Linkedin:
## https://www.linkedin.com/in/etamaki/
##
## Or type 'help' and hit return to learn how to explore.


`;

const helpText = `
OK. Now, let me show some commands:

---------------------------------------------------------------

 COMMAND xp
 The xp command, It shows when I used some technology and
 which was my role at the project.

 SYNOPSIS
     xp [--tech=technology_name] [--role=role_name]

 EXAMPLES
     xp --tech=react
     xp --tech=ruby
     xp --tech=react --role=manager
     xp --role=founder

     xp --show-techs (to explore the techs you can search)
     xp --show-roles (to explore the roles you can search)

---------------------------------------------------------------

 COMMAND addme
 The command 'addme' it allows you to share your contacts
 with me and I will be in touch.

 SYNOPSIS
     addme [contact address] [type]

 EXAMPLES
     addme your@email.com email
     addme +55119999999999 phone
     addme yourlinkedinaddress linkedin

---------------------------------------------------------------

 COMMAND meeting
 The command 'meeting' you can schedule a meeting with me.

 EXAMPLE
     meeting

---------------------------------------------------------------

 COMMAND contacts
 This command will show my contact to you be in touch.

 EXAMPLE
     contacts

---------------------------------------------------------------

 COMMAND message
 Send me a message

 SYNOPSYS
     message "A MEANINFUL MESSAGE"

 EXAMPLE
     message "LOREM IPSUM DOLOR SIT AMET."

---------------------------------------------------------------
`;

export { entryText, helpText };