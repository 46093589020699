import {
  OutputFactory
} from 'javascript-terminal';
import Discord from "../functions/discord";

export default {
    "function": (state, opts) => {
        const userInput = opts.join(' ');
        Discord(userInput);
        return {
            output:  OutputFactory.makeTextOutput(
              "Thanks... I will be in touch!"
            )
        };
    },
    "optDef": {}
}
