import React from 'react';
import ReactTerminal, {ReactThemes} from 'react-terminal-component';
import { ReactOutputRenderers } from 'react-terminal-component';
import {
    EmulatorState, OutputFactory, CommandMapping, FileSystem,
    Outputs, defaultCommandMapping
} from 'javascript-terminal';

import {entryText, helpText} from './db/texts';
import ContactsCommand from './commands/contacts';
import MeetingCommand from './commands/meeting';
import XpCommand from './commands/xp'
import MessageCommand from './commands/message'
import AddMeCommand from './commands/addme';
import AcademicPath from './paths/academic'
import ProjectsPath from './paths/projects';
import SudoCommand from './commands/sudo';

import PaperOutput, {createPaperRecord} from './outputs/paper'

function App() {
    const customState = EmulatorState.create({
        'fs': FileSystem.create({
            ...AcademicPath,
            ...ProjectsPath,
            '/hobbies/guitar': { content: "Still trying to memorize the circles of 4th and 5th. Great Rocksmith player!"},
            '/hobbies/learn': { content: `Trying to learn new things everyday!`},
            '/family/twins': { content: "private"},
            '/family/wife': { content: "private" },
        }),
        'commandMapping': CommandMapping.create({
            ...defaultCommandMapping,
            'print': {
                'function': (state, opts) => {
                    const userInput = opts.join(' ');

                    return {
                        output: createPaperRecord('A custom renderer', userInput)
                    };
                },
                'optDef': {}
            },
            'help': {
                'function': (state, opts) => {
                    return {
                        output:  OutputFactory.makeTextOutput(helpText)
                    };
                },
                'optDef': {}
            },
            'contacts': ContactsCommand,
            'meeting': MeetingCommand,
            'xp': XpCommand,
            'addme': AddMeCommand,
            'message': MessageCommand,
            'sudo': SudoCommand,
            'ssh': SudoCommand,
            'scp': SudoCommand,
            'mv': SudoCommand,
            'rm': SudoCommand,
            'cp': SudoCommand,
        })
    });
    const defaultOutputs = customState.getOutputs();

    const newOutputs = Outputs.addRecord(
        defaultOutputs, OutputFactory.makeTextOutput(
            entryText
        )
    );
    const emulatorState = customState.setOutputs(newOutputs);

    const emulatorTheme = {
        ...ReactThemes.dye,height: '100%',
        width: '100%',
        overflow: 'scroll',
        marginBottom: 20,
        promptSymbolColor: '#3aff00',
        background: '#272938',
        padding: '0px',
        lineBreak: "auto"
    }

    return (
    <div style={{height: '98%', width: '98%'}}>
      <ReactTerminal
          theme={emulatorTheme}
          emulatorState={emulatorState}
          promptSymbol='eduardotamaki >'
          outputRenderers={{
              ...ReactOutputRenderers,
              ...PaperOutput,
          }}
      />
    </div>
  );
}

export default App;
