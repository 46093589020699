import ProjectsDB from '../db/projects';
export const formatProject = (project) => {
  return `## Name: ${project.name}
## Description: ${project.description}
## Company: ${project.company}
## Techs: ${project.technologies.join(", ")}
## Roles: ${project.roles.join(", ")}
## Years: ${project.years.join(", ")}
`
}
let projectsPath = {}; 

ProjectsDB.forEach(p => {
  const pKey= `/projects/${p.slug}`;
  projectsPath = { ...projectsPath,
    [pKey]: {content: formatProject(p)}
  }
})

export default projectsPath;