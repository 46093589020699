import React from 'react';
import {
  OutputFactory
} from 'javascript-terminal';

const PAPER_TYPE = 'paper';

export const createPaperRecord = (title, body) => {
    return new OutputFactory.OutputRecord({
        type: PAPER_TYPE,
        content: {
            title,
            body
        }
    });
};

const PaperOutput = ({ content }) => (
    <div>
        <h1>{content.title}</h1>
        {content.body}
    </div>
);

export default {[PAPER_TYPE]: PaperOutput}